/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Absolute month defined by year, month and date convention. */
export interface AbsoluteMonth {
  /** @format int32 */
  year: number;
  /**
   * January = 1
   * ...
   * December = 12.
   * @format int32
   * @default 12
   */
  month?: number;
  /**
   * In this case the convention describes the convention of the month.
   * @default "EndOf"
   */
  convention?: DateConvention;
}

export interface Account {
  key?: Key | null;
  entries?: AmountEntry[] | null;
  /** Present for parent accounts, null for leaf accounts. */
  subAccountKeys?: Key[] | null;
}

export enum AccountType {
  Salary = "Salary",
  Dreams = "Dreams",
  CompanyEquityGross = "CompanyEquityGross",
  CompanyEquityNet = "CompanyEquityNet",
  CompanyDividendGross = "CompanyDividendGross",
  CompanyDividendNet = "CompanyDividendNet",
  CompanyTopTax = "CompanyTopTax",
  EmergencySavings = "EmergencySavings",
  EmergencySavingsDeposit = "EmergencySavingsDeposit",
  LiquidityAccount = "LiquidityAccount",
  HouseValue = "HouseValue",
  HouseEquity = "HouseEquity",
  LoanPrincipal = "LoanPrincipal",
  LoanInterest = "LoanInterest",
  LoanRepayment = "LoanRepayment",
  LoanFees = "LoanFees",
  LoanInstallmentGross = "LoanInstallmentGross",
  LoanInstallmentNet = "LoanInstallmentNet",
  PensionContributionGross = "PensionContributionGross",
  PensionContributionNet = "PensionContributionNet",
  PensionDepotGross = "PensionDepotGross",
  PensionDepotNet = "PensionDepotNet",
  PensionPayout = "PensionPayout",
  PensionPayoutAtDeath = "PensionPayoutAtDeath",
  PensionCost = "PensionCost",
  RiskCoverageContributionGross = "RiskCoverageContributionGross",
  RiskCoverageContributionNet = "RiskCoverageContributionNet",
  RiskCoveragePayout = "RiskCoveragePayout",
  RiskCoveragePayoutToChildren = "RiskCoveragePayoutToChildren",
  InsuranceSumTax = "InsuranceSumTax",
  TaxableIncome = "TaxableIncome",
  TopTaxTaxationBase = "TopTaxTaxationBase",
  TotalIncome = "TotalIncome",
  TotalTax = "TotalTax",
  DisposableIncome = "DisposableIncome",
  ChangeInSavingsNet = "ChangeInSavingsNet",
  UkBasicRateTax = "UkBasicRateTax",
  UkHigherRateTax = "UkHigherRateTax",
  UkAdditionalRateTax = "UkAdditionalRateTax",
  DkAmbTax = "DkAmbTax",
  DkBottomTax = "DkBottomTax",
  DkTopTax = "DkTopTax",
  DkMunicipalityTax = "DkMunicipalityTax",
  DkStatePensionPayout = "DkStatePensionPayout",
}

/** All values are expected to be the values at the time of calculation. */
export type AmortizedLoan = LoanBase & {
  /** @format double */
  remainingPrincipal: number;
  /**
   * @format int32
   * @default 30
   */
  remainingTenure?: number;
  /** @format int32 */
  remainingRepaymentFreeYears?: number;
  /**
   * @format double
   * @default 0.03
   */
  interestRate?: number;
  /**
   * @format double
   * @default 0
   */
  contributionFeeRate?: number;
};

export type AmountEntry = EntryBase & {
  /**
   * Amount.
   * @format double
   */
  amount: number;
};

export type Annuity = PayOutType & {
  /** The end date of the pay out. Defaults to 10 years after the pay out start date. */
  payOutEndDate?: AbsoluteMonth | null;
};

export interface AssetRealBase {
  status?: AssetRealBaseStatus | null;
  acquiringYear?: Time | null;
  sellingYear?: Time | null;
  /** @format double */
  value?: number | null;
  nominalValue?: boolean | null;
  growth?: PercentageSeries | null;
  /** @format int32 */
  id?: number | null;
  description?: string | null;
  ownership?: Ownership | null;
}

export enum AssetRealBaseStatus {
  EXISTING = "eXISTING",
  FUTUREACQUISITION = "fUTURE_ACQUISITION",
  FUTUREGIFT = "fUTURE_GIFT",
}

export type AssetRealEstate = AssetRealBase & {
  type?: AssetRealEstateType | null;
};

export enum AssetRealEstateType {
  Ejerbolig = "ejerbolig",
  Fritidshus = "fritidshus",
  Andelsbolig = "andelsbolig",
}

export enum AssetType {
  Cash = "Cash",
  Stocks = "Stocks",
  Bonds = "Bonds",
}

export type AssetVehicle = AssetRealBase & object;

/** The recommendation uses goal seeking to obtain the specified average disposable income in the retirement period. */
export type AverageDisposableIncomeGoalSeek = PensionRecommendation & {
  /**
   * @format double
   * @min 0
   */
  averageMonthlyDisposableIncome: number;
};

export interface CalculateInsuranceRequest {
  primary: PersonInput;
  spouse?: PersonInput | null;
  policies?: Policy[] | null;
  /** List of the incomes of the user (including spouse). */
  incomes?: Income[] | null;
  recommendation?: InsuranceRecommendation | null;
  /**
   * The municipality Id of the Danish city of residence. Used for correct tax calculations.
   * Expected format is: "XXX", where X is an integer. Leading zeros are removed. Defaults to the default value if not set or not in the expected format.
   * An invalid, correctly formatted municipality Id results in an error.
   * @default "165"
   */
  municipalityId?: string | null;
  /**
   * Calculation start date. Different calculation start dates lead to different calculation intervals. Should generally be set to current year.
   * Currently only beginning of year is supported.
   * @default "2025 BoY"
   */
  calculationStart?: AbsoluteMonth | null;
  /**
   * The country used for the pension calculation.
   * @default "Dk"
   */
  calculationCountry?: CalculationCountry;
  /** The output configuration. It is possible to configure whether the statement should be returned, which statement accounts to return etc. */
  outputConfiguration?: OutputConfiguration | null;
}

export interface CalculateInsuranceResponse {
  insuranceMetrics?: InsuranceBaselineAndRecommendedOutput | null;
  statements?: InsuranceStatementResponse | null;
}

export interface CalculatePensionRequest {
  primary: PersonInput;
  spouse?: PersonInput | null;
  policies?: Policy[] | null;
  /** If no recommendation settings are specifically set, the default option for the various economic domains is selected. */
  recommendationSettings?: RecommendationSettings | null;
  /** List of the incomes of the user (including spouse). */
  incomes?: Income[] | null;
  /**
   * The municipality Id of the Danish city of residence. Used for correct tax calculations.
   * Expected format is: "XXX", where X is an integer. Leading zeros are removed. Defaults to the default value if not set or not in the expected format.
   * An invalid, correctly formatted municipality Id results in an error.
   * @default "165"
   */
  municipalityId?: string | null;
  /**
   * Calculation start date. Different calculation start dates lead to different calculation intervals. Should generally be set to current year.
   * Currently only beginning of year is supported.
   * @default "2024 BoY"
   */
  calculationStart?: AbsoluteMonth | null;
  /**
   * The country used for the pension calculation.
   * @default "Dk"
   */
  calculationCountry?: CalculationCountry;
  /** The output configuration. It is possible to configure whether the statement should be returned, which statement accounts to return etc. */
  outputConfiguration?: OutputConfiguration | null;
}

export interface CalculatePensionResponse {
  pensionOutput?: PensionBaselineAndRecommendedOutput | null;
  statements?: StatementResponse | null;
}

export interface CalculateRequest {
  primary: PersonInput;
  spouse?: PersonInput | null;
  housesWithLoans?: HouseWithLoans[] | null;
  incomes?: Income[] | null;
  liquidAssets?: LiquidAsset[] | null;
  companies?: (IncorporatedCompany | PersonalCompany)[] | null;
  policies?: Policy[] | null;
  /** If no recommendation settings are specifically set, the default option for the various economic domains is selected. */
  recommendationSettings?: RecommendationSettings | null;
  dreams?: Dream[] | null;
  /**
   * The municipality id of the Danish city of residence. Used for correct tax calculations.
   * Expected format is: "XXX", where X is an integer. Leading zeros are removed. Defaults to the default value if not set or not in the expected format.
   * An invalid, correctly formatted municipality id results in an error.
   * Not used for UK calculation.
   * @default "165"
   */
  municipalityId?: string | null;
  /**
   * Calculation start date. Different calculation start dates lead to different calculation intervals. Should generally be set to current year.
   * Currently only beginning of year is supported.
   * @default "2025 BoY"
   */
  calculationStart?: AbsoluteMonth | null;
  /**
   * The country used for the pension calculation.
   * @default "Dk"
   */
  calculationCountry?: CalculationCountry;
  outputConfiguration?: OutputConfiguration | null;
}

/** The different outputs from the calculators can be null, if that calculation does not apply to the input data. */
export interface CalculateResponse {
  messages?: Message[] | null;
  statements?: StatementResponse | null;
  houseAndMortgageOutput?: HousingBaselineAndRecommendedOutput | null;
  emergencySavingsOutput?: EmergencySavingsBaselineAndRecommendedOutput | null;
  pensionOutput?: PensionBaselineAndRecommendedOutput | null;
  companyOutput?: CompanyBaselineAndRecommendedOutput | null;
  liquidSavingsOutput?: SavingsBaselineAndRecommendedOutput | null;
  overallResultOutput?: OverallResultBaselineAndRecommendedOutput | null;
}

export enum CalculationCountry {
  Dk = "Dk",
  Uk = "Uk",
  DkInHouse = "DkInHouse",
}

export interface CalculationInformation {
  liquitityRatePositiv?: PercentageSeries | null;
  liquitityRateNegativ?: PercentageSeries | null;
}

/** The recommendation is specified through the monthly contribution and initial one time contribution. */
export type CashSavingsWithSpecifiedContribution = EmergencySavingRecommendation & {
  /**
   * If the cash savings is defined, the savings plan seeks to uses the other defined values and the length of the saving period to meet the desired cash savings.
   * In the case where the initial and monthly contribution is not defined, the [DefinedCashSavings](#model-DefinedCashSavings) are used.
   * If not, the one time contribution and ongoing monthly contribution will act freely.
   * @format double
   */
  cashSavings?: number | null;
  /** @format double */
  initialOneTimeContribution?: number | null;
  /**
   * The monthly contribution assumes contribution over 2 year period. If the cashSavings are defined, it might however be over a different yearly period,
   * as the contribution length is the only independent variable.
   * @format double
   */
  monthlyContribution?: number | null;
};

/** The recommendation calculates the required cash to meet the cash to gross salary ratio and obtain it through existing savings and/or a fixed savings rate over a predefined amount of time. */
export type CashToSalaryRatio = EmergencySavingRecommendation & {
  /**
   * @format double
   * @default "125 percent"
   */
  ratio?: number;
};

export interface Child {
  name?: string | null;
  birthDate?: PersonBirthDate | null;
  benefitReceiver?: ChildBenefitReceiver | null;
}

export enum ChildBenefitReceiver {
  Primary = "primary",
  Spouse = "spouse",
}

export interface ChosenCoverageConfiguration {
  coverageId: string;
  /**
   * The chosen contribution for the specific coverage.
   * In case the underlying coverage has defined price steps, the contribution needs to match the contribution of one of the price steps. If not, it needs to be within the price range or other limits.
   * @format double
   */
  chosenContribution: number;
}

export type CompanyBase = EntityBase & {
  type: string;
  /**
   * The expected yearly EBITDA. It is seen as being constant over the lifetime of the company.
   * @format double
   */
  yearlyEbitda: number;
  /** @format double */
  initialEquity?: number;
  /**
   * Expected nominal company return in percentage.
   * If not given, the expected market average value will be used as default.
   */
  expectedReturn?: PercentageEntry[] | null;
};

export interface CompanyBaselineAndRecommendedOutput {
  baseline?: CompanyOutput | null;
  recommendation?: CompanyOutput | null;
  /** @format double */
  netCompanyEquityAtRetirementDelta?: number;
}

export interface CompanyOutput {
  /** @format double */
  netEquityAtRetirement?: number;
}

export interface CompanyRecommendation {
  /**
   * * [NoCompanyRecommendation](#model-NoCompanyRecommendation)
   * * [SpecifiedDividends](#model-SpecifiedDividends)
   * * [TopTaxOptimizedDividends](#model-TopTaxOptimizedDividends)
   */
  type: string;
}

export type Contribution = PaymentBase & {
  /**
   * True, if the contribution is through an employer.
   * @default true
   */
  isFromEmployer?: boolean;
  /**
   * A Danish specific configuration in regards to whether the contribution includes or excludes the AMB (labor market contribution tax)
   * @default false
   */
  isInclusiveAmb?: boolean | null;
};

export type Coverage = EntityBase & {
  type: string;
  owner: Owner;
};

export interface Date {
  /** @format int32 */
  year?: number | null;
  /** @format int32 */
  month?: number | null;
  /** @format int32 */
  day?: number | null;
}

/** Defines the interpretation of a date. */
export enum DateConvention {
  BeginningOf = "BeginningOf",
  EndOf = "EndOf",
}

export type DeathCoverageRatioGoalSeek = DeathRecommendation & {
  /** @format double */
  disposableIncomeToYearRatio?: number;
};

export type DeathDisposableIncomeGoalSeek = DeathRecommendation & {
  /**
   * The disposable income of the spouse is considered, if spouse exists. Otherwise, the event person's disposible income is used.
   * @format double
   * @min 0
   */
  averageMonthlyDisposableIncome: number;
};

export interface DeathRecommendation {
  /**
   * * [DeathCoverageRatioGoalSeek](#model-DeathCoverageRatioGoalSeek)
   * * [DeathDisposableIncomeGoalSeek](#model-DeathDisposableIncomeGoalSeek)
   * * [DeathSpecifiedConfiguration](#model-DeathSpecifiedConfiguration)
   */
  type: string;
}

export type DeathSpecifiedConfiguration = DeathRecommendation & {
  chosenCoverageConfigurations: ChosenCoverageConfiguration[];
};

export interface Deduction {
  growth?: PercentageSeries | null;
  amount?: PaymentEntry | null;
  type?: DeductionType | null;
  /** @format int32 */
  id?: number | null;
  description?: string | null;
  ownership?: Ownership | null;
}

export enum DeductionType {
  LigningsmaessigeFradrag = "ligningsmaessigeFradrag",
  FradragForTidligerePensionsindskud = "fradragForTidligerePensionsindskud",
  Befordringsfradrag = "befordringsfradrag",
  FradragForTidligerePensionsindskudLivrente = "fradragForTidligerePensionsindskudLivrente",
  Fagforening = "fagforening",
  AKasseOgEfterloen = "aKasseOgEfterloen",
  Koerselsfradrag = "koerselsfradrag",
  Underholdsbidrag = "underholdsbidrag",
  GaverTilForeninger = "gaverTilForeninger",
  UdgifterTilFrededeBygninger = "udgifterTilFrededeBygninger",
  SaerligtFradragTilDagplejereMv = "saerligtFradragTilDagplejereMv",
}

/** The recommendation calculates the required cash flow to meet the defined rainy day cash savings and obtain it through existing savings and/or a fixed savings rate over a predefined amount of time. */
export type DefinedCashSavings = EmergencySavingRecommendation & {
  /**
   * @format double
   * @default "125 percent of salary"
   */
  cashSavings?: number | null;
};

export type DisabilityCoverageRatioGoalSeek = DisabilityRecommendation & {
  /**
   * In the case of disability, the period until retirement age is considered by default.
   * @format double
   */
  ratio?: number;
};

export type DisabilityDisposableIncomeGoalSeek = DisabilityRecommendation & {
  /**
   * @format double
   * @min 0
   */
  averageMonthlyDisposableIncome: number;
};

export interface DisabilityRecommendation {
  /**
   * * [DisabilityCoverageRatioGoalSeek](#model-DisabilityCoverageRatioGoalSeek)
   * * [DisabilityDisposableIncomeGoalSeek](#model-DisabilityDisposableIncomeGoalSeek)
   * * [DisabilitySpecifiedConfiguration](#model-DisabilitySpecifiedConfiguration)
   */
  type: string;
}

export type DisabilitySpecifiedConfiguration = DisabilityRecommendation & {
  /** A list of predefined chosen coverage contributions. Replaces any recommendation algorithm, and uses this instead as the chosen recommendation. */
  chosenCoverageConfigurations: ChosenCoverageConfiguration[];
};

export type DkRiskCoverageInformation = RiskCoverageInformation & {
  /**
   * The risk coverage type. Currently just defined as a subset of the possible Danish risk coverage types.
   * @default "LumpSumAtDeathTaxCode5"
   */
  coverageType?: DkRiskCoverageType;
};

export enum DkRiskCoverageType {
  AnnuityAtDeath = "AnnuityAtDeath",
  LumpSumAtDeathTaxCode2 = "LumpSumAtDeathTaxCode2",
  LumpSumAtDeathTaxCode5 = "LumpSumAtDeathTaxCode5",
  LumpSumAtDisabilityTaxCode2 = "LumpSumAtDisabilityTaxCode2",
  LumpSumAtDisabilityTaxCode5 = "LumpSumAtDisabilityTaxCode5",
  AnnuityAtDisability = "AnnuityAtDisability",
  CriticalIllness = "CriticalIllness",
  AccidentInsuranceAtDeath = "AccidentInsuranceAtDeath",
  LumpSumAtDeathToChildrenTaxCode5 = "LumpSumAtDeathToChildrenTaxCode5",
}

export enum DkTaxCode {
  One = "One",
  Two = "Two",
  Three = "Three",
  Five = "Five",
  Seven = "Seven",
  Nine = "Nine",
  ThirtyThree = "ThirtyThree",
}

export interface DownsavingLoan {
  /** @format double */
  maxDownsavingLoanAmount?: number | null;
  growth?: DownsavingLoanGrowth | null;
}

export enum DownsavingLoanGrowth {
  NoGrowth = "noGrowth",
  FollowInflation = "followInflation",
  FollowRealEstate = "followRealEstate",
}

export interface Dream {
  /** Absolute month date of the dream. Note: currently only the year is important. The Dream is ignored, if it occurs outside the calculation interval. */
  date?: AbsoluteMonth | null;
  /** @format double */
  cost?: number;
}

export interface EarlyRetirement {
  earlyRetirementStatus?: EarlyRetirementStatus | null;
  /** @format double */
  earlyRetirementWorkHours?: number | null;
  /** @format int32 */
  earlyRetirementAge?: number | null;
  /** @format double */
  earlyRetirement24MonthsCappedIncome?: number | null;
}

export enum EarlyRetirementStatus {
  None = "none",
  FullTime = "fullTime",
  PartTime = "partTime",
  FlexibleBenefit = "flexibleBenefit",
  InPayment = "inPayment",
}

export interface EmergencySavingRecommendation {
  /**
   * * [CashSavingsWithSpecifiedContribution](#model-CashSavingsWithSpecifiedContribution)
   * * [CashToSalaryRatio](#model-CashToSalaryRatio)
   * * [DefinedCashSavings](#model-DefinedCashSavings)
   */
  type: string;
}

export interface EmergencySavingsBaselineAndRecommendedOutput {
  baseline?: EmergencySavingsOutput | null;
  recommendation?: EmergencySavingsOutput | null;
  /** @format double */
  emergencySavingsDelta?: number;
}

export interface EmergencySavingsOutput {
  /**
   * Emergency savings (cash) to gross salary ratio.
   * @format double
   */
  cashToGrossSalaryRatio?: number;
  /** @format double */
  depotAtPension?: number;
  /**
   * The average monthly savings (if positive) or withdrawals (if negative).
   * @format double
   */
  averageMonthlySavingsUntilPension?: number;
}

export interface EntityBase {
  /** Creates a new id of type Cuid, if not set. */
  id?: string | null;
}

export interface EntryBase {
  /** Month of the entry. */
  entryDate: AbsoluteMonth;
}

export interface EstablishmentDetails {
  /** @format double */
  foundationFee?: number;
  /**
   * Only relevant for bond based mortgages. Otherwise, the loan proceeds is deemed equal to the loan principal baring costs.
   * @format double
   */
  bondPrice?: number | null;
}

export interface Expense {
  growth?: PercentageSeries | null;
  amount?: PaymentEntry | null;
  type?: ExpenseType | null;
  /** @format int32 */
  assetId?: number | null;
  /** @format int32 */
  id?: number | null;
  description?: string | null;
  ownership?: Ownership | null;
}

export interface ExpenseGroup {
  description?: string | null;
  expenses?: Expense[] | null;
  deductions?: Deduction[] | null;
  asset?: AssetRealBase | AssetVehicle | OptimizationAssetRealEstate | AssetRealEstate | null;
  category?: ExpenseGroupCategory | null;
}

export enum ExpenseGroupCategory {
  Other = "other",
  House = "house",
  Vehicle = "vehicle",
  Child = "child",
}

export enum ExpenseType {
  HusholdningMadToej = "husholdningMadToej",
  Kontingenter = "kontingenter",
  Forsikringer = "forsikringer",
  Forbrugsudgifter = "forbrugsudgifter",
  FerierOgRejser = "ferierOgRejser",
  Helse = "helse",
  Jobudgifter = "jobudgifter",
  HobbyOgFritid = "hobbyOgFritid",
  Lommepenge = "lommepenge",
  LoebendeUdgifterEjerbolig = "loebendeUdgifterEjerbolig",
  LoebendeUdgifterBil = "loebendeUdgifterBil",
  DiverseUdgifter = "diverseUdgifter",
  AndenUdgift = "andenUdgift",
  Ejendomsskat = "ejendomsskat",
  Ejendomsvaerdiskat = "ejendomsvaerdiskat",
  El = "el",
  Vand = "vand",
  Varme = "varme",
  VedligeholdelseAfBolig = "vedligeholdelseAfBolig",
  Renovation = "renovation",
  Skorstensfejer = "skorstensfejer",
  Grundejerforening = "grundejerforening",
  Faellesudgifter = "faellesudgifter",
  Husforsikring = "husforsikring",
  Braendstof = "braendstof",
  Bilforsikring = "bilforsikring",
  GroenEjerafgift = "groenEjerafgift",
  VedligeholdelseAfBil = "vedligeholdelseAfBil",
  Vejhjaelp = "vejhjaelp",
  Vuggestue = "vuggestue",
  Boernehave = "boernehave",
  Tandlaege = "tandlaege",
  Familieforsikring = "familieforsikring",
  Ulykkesforsikring = "ulykkesforsikring",
  Rejseforsikring = "rejseforsikring",
  Telefon = "telefon",
  Internet = "internet",
  TVPakker = "tVPakker",
}

export enum HealthStatus {
  Unhealthy = "Unhealthy",
  Degraded = "Degraded",
  Healthy = "Healthy",
}

export type HouseBase = EntityBase & {
  type: string;
  loans?: AmortizedLoan[] | null;
};

export type HouseWithLoans = HouseBase & {
  /**
   * House value at the time of calculation.
   * @format double
   */
  houseValue: number;
  /**
   * House nominal growth percentage.
   * If not given, the expected growth for real estate in Denmark will be used.
   */
  houseGrowth?: PercentageEntry[] | null;
};

export type Housing = HousingBase & {
  /** @format int32 */
  municipalityId?: number | null;
  /** @format int32 */
  size?: number | null;
  heating?: HousingHeating | null;
};

export interface HousingBase {
  /** @format int32 */
  id?: number | null;
  description?: string | null;
  type?: HousingBaseType | null;
  isResidence?: boolean | null;
}

export enum HousingBaseType {
  House = "house",
  HolidayHome = "holidayHome",
  OwnedApartment = "ownedApartment",
  RentedApartment = "rentedApartment",
  Dormitory = "dormitory",
  AtParents = "atParents",
  Cooperative = "cooperative",
}

export interface HousingBaselineAndRecommendedOutput {
  /** Represents aggregate housing output values for all houses combined. */
  baseline?: HousingOutputAggregate | HousingOutputAggregateRecommendation | null;
  /** Represents aggregated housing output values for recommendation, including refinancing details. */
  recommendation?: HousingOutputAggregateRecommendation | null;
  /** @format double */
  houseEquityAtPensionDelta?: number;
  /** @format double */
  averageMonthlyInstallmentUntilPensionDelta?: number;
  /** @format double */
  netAverageMonthlyInstallmentUntilPensionDelta?: number;
}

export enum HousingHeating {
  DistrictHeating = "districtHeating",
  Oil = "oil",
  Electricity = "electricity",
  Gas = "gas",
  Other = "other",
}

export interface HousingOutput {
  /**
   * The LTV ratio at retirement. 0.6 would mean a 60% LTV ratio.
   * @format double
   */
  loanToValueRatioAtPension?: number;
  /** @format double */
  houseEquityAtPension?: number;
  /**
   * Gross.
   * @format double
   */
  averageMonthlyInstallmentUntilPension?: number;
  /** @format double */
  netAverageMonthlyInstallmentUntilPension?: number;
}

/** Represents aggregate housing output values for all houses combined. */
export interface HousingOutputAggregate {
  /** @format double */
  loanToValueRatioAtPension?: number;
  /** @format double */
  houseEquityAtPension?: number;
  /** @format double */
  averageMonthlyInstallmentUntilPension?: number;
  /** @format double */
  netAverageMonthlyInstallmentUntilPension?: number;
  /** List of individual housing outputs for each house. */
  houseList?: HousingOutput[] | null;
}

/** Represents aggregated housing output values for recommendation, including refinancing details. */
export type HousingOutputAggregateRecommendation = HousingOutputAggregate & {
  refinancingDetailsAggregate?: RefinancingDetails | null;
  /** List of refinancing details for each loan, detailing individual refinancing actions. */
  refinancingDetailsList?: RefinancingDetails[] | null;
};

export type HousingWithBbr = HousingBase & {
  /** @format int64 */
  bbrNumber?: number | null;
};

export type HousingWithValue = Housing & {
  /** @format int64 */
  propertyAppraisal2001?: number | null;
  /** @format int64 */
  propertyAppraisal2002?: number | null;
  /** @format int64 */
  propertyAppraisal2011?: number | null;
  /** @format int32 */
  landValueAppraisal?: number | null;
  acquiredDate?: Date | null;
};

export type Income = EntityBase & {
  owner: Owner;
  payment: Payment;
  /**
   * The expected nominal growth of the income.
   * If not given, the expected inflation rate in Denmark will be used as default.
   */
  expectedGrowth?: PercentageEntry[] | null;
};

export type IncorporatedCompany = CompanyBase & {
  /**
   * The current expected yearly pay out of dividends.
   * @format double
   */
  dividends?: number;
};

export interface InsuranceBaselineAndRecommendedOutput {
  baseline?: InsuranceOutput | null;
  recommendation?: InsuranceOutput | null;
}

export interface InsuranceOutput {
  /**
   * First year gross monthly contribution to all insurances.
   * @format double
   */
  grossMonthlyContribution?: number;
  death?: InsuranceOutputDeath | null;
  disability?: InsuranceOutputDisability | null;
  /** @format double */
  totalCriticalIllnessPayout?: number;
  /** @format double */
  totalAccidentInsurancePayout?: number;
}

export interface InsuranceOutputDeath {
  /** @format double */
  lumpSumNet?: number;
  /** @format double */
  lumpSumToChildren?: number;
  /** @format double */
  averageMonthlyDisposableIncome?: number;
}

export interface InsuranceOutputDisability {
  /** @format double */
  lumpSumNet?: number;
  /** @format double */
  averageMonthlyDisposableIncomeUntilRetirement?: number;
  /** @format double */
  coverageRatio?: number | null;
}

export interface InsuranceRecommendation {
  death?: DeathCoverageRatioGoalSeek | DeathDisposableIncomeGoalSeek | DeathSpecifiedConfiguration | null;
  disability?:
    | DisabilityCoverageRatioGoalSeek
    | DisabilityDisposableIncomeGoalSeek
    | DisabilitySpecifiedConfiguration
    | null;
  /** The product to use for insurance recommendation. Only possible with specified policy products for now. */
  product?: SpecifiedPolicyRecommendationProduct | null;
}

export interface InsuranceStatementResponse {
  baseline?: InsuranceStatements | null;
  recommendation?: InsuranceStatements | null;
}

export interface InsuranceStatements {
  noEvent?: Statement | null;
  disability?: Statement | null;
  death?: Statement | null;
}

export interface Key {
  inputId?: string | null;
  personType?: PersonType;
  accountType?: AccountType;
}

export type LifeAnnuity = PayOutType & object;

export type LiquidAsset = EntityBase & {
  assetType: AssetType;
  /** @format double */
  initialValue: number;
  monthlyDeposit?: Payment | null;
  /** Expected nominal liquidity return in percentage. If not given, the expected market average in Denmark will be used as default. */
  expectedReturn?: PercentageEntry[] | null;
};

export type LoanBase = EntityBase & {
  type: string;
  /**
   * The redemption details associated with the loan with an early redemption.
   * @default null
   */
  redemptionDetails?: RedemptionDetails | null;
};

export interface LoanRecommendation {
  /**
   * * [LoanToValueRatioGoalSeek](#model-LoanToValueRatioGoalSeek)
   * * [RestrictedLoanToValueRatioGoalSeek](#model-RestrictedLoanToValueRatioGoalSeek)
   * * [SpecifiedAmortizationLoan](#model-SpecifiedAmortizationLoan)
   */
  type: string;
  /** The establishment details related to the loan recommendation. If not specified, the establishment is free. */
  establishmentDetails?: EstablishmentDetails | null;
  /** @format double */
  interestRate?: number | null;
  /** @format double */
  contributionFeeRate?: number | null;
}

/** The recommendation uses goal seeking to obtain the specified loan-to-value ratio. If no ratio is specified the default value is used. */
export type LoanToValueRatioGoalSeek = LoanRecommendation & {
  /**
   * @format double
   * @default "50 percent"
   */
  ratio?: number;
};

export type LumpSum = PayOutType & object;

export interface Message {
  description?: string | null;
  /** Message codes for warnings. */
  messageCode?: MessageCode;
  /** Scenario type for messages. */
  scenario?: Scenario;
}

/** Message codes for warnings. */
export enum MessageCode {
  TargetCoverageRatioIsTooLowBasedOnBaselineCoverageRatio = "TargetCoverageRatioIsTooLowBasedOnBaselineCoverageRatio",
  TargetDisposableIncomeIsTooLowBasedOnBaselineDisposableIncome = "TargetDisposableIncomeIsTooLowBasedOnBaselineDisposableIncome",
  LiquiditySavingsDepotHasNegativePeriods = "LiquiditySavingsDepotHasNegativePeriods",
  LiquiditySavingsDepotIsNegativeAtRetirement = "LiquiditySavingsDepotIsNegativeAtRetirement",
  TargetResultsInAnAverageRetirementMuchHigherThanTheFirstYearDisposableIncome = "TargetResultsInAnAverageRetirementMuchHigherThanTheFirstYearDisposableIncome",
}

/** No company recommendation is used, and the output is therefore the same as for the baseline scenario. */
export type NoCompanyRecommendation = CompanyRecommendation & object;

export type OptimizationAssetRealEstate = AssetRealEstate & {
  downsavingLoan?: DownsavingLoan | null;
};

export interface OutputConfiguration {
  /** @default true */
  includeStatementInOutput?: boolean;
  /**
   * Number of decimal places to round the time-series value to. Default is 0.
   * @format int32
   * @default 0
   */
  roundingPrecision?: number;
  /**
   * List of filtered statement account types to include in the statement output.
   * If null, all account types are included.
   */
  accountTypesFilter?: AccountType[] | null;
  /**
   * Specifies whether to perform a recommendation calculation or not. If false, all recommendation and delta outputs will be null.
   * Is not supported by CalculateTargetPrices yet.
   * @default true
   */
  includeRecommendation?: boolean;
}

export interface OverallResultBaselineAndRecommendedOutput {
  baseline?: OverallResultOutput | null;
  recommendation?: OverallResultOutput | null;
  /** @format double */
  householdWealthAtPensionDelta?: number;
  /** @format double */
  averageChangeInSavingsPrMonth?: number;
}

export interface OverallResultOutput {
  /** @format double */
  householdWealthAtPension?: number;
}

export enum Owner {
  Primary = "Primary",
  Spouse = "Spouse",
}

export interface Ownership {
  /** @format double */
  spouse?: number | null;
  /** @format double */
  primary?: number | null;
}

export interface PayOutType {
  /**
   * * [Annuity](#model-Annuity)
   * * [LifeAnnuity](#model-LifeAnnuity)
   * * [LumpSum](#model-LumpSum)
   */
  type: string;
  /** The start date of the payout. Defaults to the retirement time for the owner. */
  payOutDate?: AbsoluteMonth | null;
}

export type Payment = PaymentBase & object;

export interface PaymentBase {
  /**
   * * [Contribution](#model-Contribution)
   * * [RiskCoverageContribution](#model-RiskCoverageContribution)
   * * [PensionContribution](#model-PensionContribution)
   * * [Payment](#model-Payment)
   */
  type: string;
  /**
   * Always seen as monthly (for now).
   * @format double
   */
  amount: number;
  /**
   * Default: No end date to the contribution (seen as perpetual).
   * @default null
   */
  to?: AbsoluteMonth | null;
  /** @default "Monthly" */
  paymentFrequency?: PaymentFrequency;
}

export interface PaymentEntry {
  /** @format double */
  amount?: number | null;
  from?: Time | null;
  to?: Time | null;
  nominal?: boolean | null;
  /** @format int32 */
  timesPaidEachYear?: number | null;
}

export enum PaymentFrequency {
  Yearly = "Yearly",
  Monthly = "Monthly",
}

export enum PayoutReceiver {
  Self = "Self",
  Spouse = "Spouse",
  Children = "Children",
  Other = "Other",
}

export interface PensionBaselineAndRecommendedOutput {
  baseline?: PensionHouseholdOutput | null;
  recommendation?: PensionHouseholdOutput | null;
  householdDelta?: PensionHouseholdDelta | null;
}

export type PensionContribution = Contribution & {
  /** @format double */
  extraVoluntaryContribution?: number;
  /**
   * As a fraction of the base contribution (does not include extra voluntary contribution).
   * @format double
   * @default 0
   */
  contributionToInsuranceFraction?: number;
};

export type PensionCoverage = Coverage & {
  contribution?: PensionContribution | null;
  /** @format double */
  initialValue?: number | null;
  /**
   * The pay out type of the pension coverage. Dictates how the coverage is paid out. Does not make sense for all types of tax codes.
   * @default "Calculation.Api.Model.Request.PoliciesAndCoverages.PensionCoverageTypes.LifeAnnuity, Calculation.Api.Model, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
   */
  payOutType?: Annuity | LifeAnnuity | LumpSum | null;
  /**
   * The tax code of the pension coverage. Currently specific for Denmark. Subject to change.
   * @default "One"
   */
  taxCode?: DkTaxCode;
  /**
   * Predefined benefits should be used whenever the benefit has been projected by a third party or is known.
   * The calculation will then use this benefit instead of the projected benefit by the Dreamplan calculation.
   * Currently not supported by the UK calculation.
   * @default null
   */
  predefinedBenefits?: AmountEntry[] | null;
  /**
   * Expected nominal pension coverage return. If not given, the Dreamplan default expected return for pensions will be used.
   * @default null
   */
  expectedReturn?: PercentageEntry[] | null;
  /**
   * Expected nominal asset under management cost. If not given, zero fees are assumed.
   * @default null
   */
  assetUnderManagementFee?: PercentageEntry[] | null;
};

/** The recommendation uses goal seeking to obtain the specified coverage ratio. If no coverage ratio is supplied the default value is used. */
export type PensionCoverageRatioGoalSeek = PensionRecommendation & {
  /**
   * @format double
   * @default "75 percent"
   */
  ratio?: number;
};

export interface PensionHouseholdDelta {
  primaryDelta?: PensionPersonDelta | null;
  /** Null if no spouse. */
  spouseDelta?: PensionPersonDelta | null;
  /** @format double */
  householdNetPensionDepotAtRetirementDelta?: number;
  /** @format double */
  averageMonthlyDisposableIncomeDuringRetirementDelta?: number;
  /**
   * First year.
   * @format double
   */
  householdNetMonthlyPaymentDelta?: number;
  /**
   * First year. Ex. AMB.
   * @format double
   */
  householdGrossMonthlyPaymentDelta?: number;
}

export interface PensionHouseholdOutput {
  primary?: PensionPerson | null;
  /** Null if no spouse. */
  spouse?: PensionPerson | null;
  /**
   * Is null if the household coverage ratio was not relevant to the specific calculation (i.e. a goal seek target of a specific person).
   * @format double
   */
  householdCoverageRatio?: number | null;
  /** @format double */
  averageMonthlyDisposableIncomeDuringRetirement?: number;
  /**
   * At the time of the last person's retirement.
   * @format double
   */
  householdPensionDepotSum?: number;
  /**
   * First year.
   * @format double
   */
  householdNetMonthlyPayment?: number;
  /**
   * First year.  Ex. AMB.
   * @format double
   */
  householdGrossMonthlyPayment?: number;
}

/** All the pay-outs are gross. */
export interface PensionPayOutOverview {
  /** @format double */
  sumPayOut?: number;
  /** @format double */
  monthlyAnnuityPayOut?: number;
  /** @format double */
  monthlyLifeAnnuityPayOut?: number;
}

export interface PensionPerson {
  /** @format double */
  coverageRatio?: number;
  /** @format double */
  netPensionDepotAtPension?: number;
  /**
   * First year.
   * @format double
   */
  netMonthlyPayment?: number;
  /**
   * First year. Ex. AMB.
   * @format double
   */
  grossMonthlyPayment?: number;
  /** @format double */
  averageMonthlyDisposableIncomeDuringRetirement?: number;
  /**
   * Gives a superficial overview of the pay-out amounts that the person will receive. Be aware that this is based on the first occurence.
   * Therefore, it will not catch multiple pay-outs happening at different times.
   */
  pensionPayOutOverview?: PensionPayOutOverview | null;
}

export interface PensionPersonDelta {
  /** @format double */
  netPensionDepotAtRetirementDelta?: number;
  /**
   * First year. Ex. AMB.
   * @format double
   */
  grossMonthlyPaymentDelta?: number;
  /**
   * First year.
   * @format double
   */
  netMonthlyPaymentDelta?: number;
  /**
   * Delta of average monthly disposable income during retirement.
   * @format double
   */
  averageMonthlyDisposableIncomeDuringRetirementDelta?: number;
}

export interface PensionRecommendation {
  /**
   * * [AverageDisposableIncomeGoalSeek](#model-AverageDisposableIncomeGoalSeek)
   * * [PensionCoverageRatioGoalSeek](#model-PensionCoverageRatioGoalSeek)
   * * [SimpleSpecifiedPension](#model-SimpleSpecifiedPension)
   * * [SpecifiedNetVoluntaryPayment](#model-SpecifiedNetVoluntaryPayment)
   */
  type: string;
  /**
   * The product to use for the pension recommendation.
   * @default "Calculation.Api.Model.Request.PoliciesAndCoverages.Recommendation.RecommendationProducts.SinglePensionRecommendationProduct, Calculation.Api.Model, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
   */
  recommendationProduct?: SinglePensionRecommendationProduct | SpecifiedPolicyRecommendationProduct | null;
}

export interface PensionRecommendationProduct {
  /**
   * * [SinglePensionRecommendationProduct](#model-SinglePensionRecommendationProduct)
   * * [SpecifiedPolicyRecommendationProduct](#model-SpecifiedPolicyRecommendationProduct)
   */
  type: string;
  /**
   * Assumes same frequency as the recommendation coverage.
   * @format double
   * @default null
   */
  minimumContribution?: number | null;
}

export interface Percent {
  /** @format double */
  asFraction?: number | null;
  /** @format double */
  asPercent?: number | null;
}

export type PercentageEntry = EntryBase & {
  /**
   * Percentage.
   * @format double
   */
  percentage: number;
};

export interface PercentageSeries {
  series?: TimeValueOfPercent[] | null;
}

export interface Person {
  name?: string | null;
  birthDate?: PersonBirthDate | null;
  /** @format int32 */
  riskScore?: number | null;
  /** @format int32 */
  pensionAge?: number | null;
  /** @format int32 */
  deathAge?: number | null;
  paysKirkeskat?: boolean | null;
  benefits?: PublicBenefits | null;
}

export interface PersonBirthDate {
  /** @format int32 */
  year?: number | null;
  /** @format int32 */
  month?: number | null;
  /** @format int32 */
  day?: number | null;
}

export interface PersonInput {
  /** @format int32 */
  birthYear: number;
  /**
   * Defaults to 69 currently.
   * @format int32
   */
  pensionAge: number;
  /**
   * The expected death age.
   * @format int32
   * @default 88
   */
  deathAge?: number;
}

export enum PersonType {
  Primary = "Primary",
  Spouse = "Spouse",
  Shared = "Shared",
}

export type PersonalCompany = CompanyBase & object;

export type Policy = EntityBase & {
  owner: Owner;
  /** The coverages of the policy. */
  coverages?: (RiskCoverage | RiskCoverageWithProductInformation | PensionCoverage)[] | null;
};

export type PriceRange = PriceRule & {
  /**
   * @format double
   * @default 0
   */
  minimumContribution?: number;
  /**
   * If set, this is the maximum contribution to this coverage and therefore also the maximum possible benefit is achieved.
   * @format double
   */
  maximumContribution?: number | null;
  /**
   * The price range of the risk coverage. Dictates what the price should be per 1000 benefit.
   * Example: if the PricePer1000Benefit is 5, then a contribution of 100,- would result in a benefit of 500.000,-.
   * @format double
   */
  pricePer1000Benefit: number;
};

export interface PriceRule {
  /**
   * * [PriceRange](#model-PriceRange)
   * * [PriceStepRule](#model-PriceStepRule)
   */
  type: string;
}

export interface PriceStep {
  id?: string | null;
  /** @format double */
  price?: number;
  /** @format double */
  benefit?: number;
}

export type PriceStepRule = PriceRule & {
  /** If not specified, use lowest pricestep by contribution as default. */
  defaultPriceStepId?: string | null;
  steps: PriceStep[];
};

export interface Profile {
  id?: ProfileId | null;
  primary?: Person | null;
  spouse?: Person | null;
  married?: boolean | null;
  children?: Child[] | null;
  /** @format int32 */
  municipalityId?: number | null;
  calculationInformation?: CalculationInformation | null;
}

export interface ProfileId {
  /** @format int32 */
  id?: number | null;
  /** @format int32 */
  basedId?: number | null;
  /** @format int32 */
  year?: number | null;
}

export interface PublicBenefits {
  /** @format int32 */
  folkepensionAge?: number | null;
  isEntitledForAeldreCheck?: boolean | null;
  isEntitledSeniorpraemieFoerste?: boolean | null;
  isEntitledSeniorpraemieAnden?: boolean | null;
  isEntitledForFolkepension?: boolean | null;
  isEntitledForFolkepensionTillaeg?: boolean | null;
  /** @format int32 */
  folkepensionBroekpensionYears?: number | null;
  opsatFolkepensionPayOutType?: PublicBenefitsOpsatFolkepensionPayOutType | null;
  isEntitledForFoertidspension?: boolean | null;
  tidligPensionYears?: PublicBenefitsTidligPensionYears | null;
  earlyRetirement?: EarlyRetirement | null;
}

export enum PublicBenefitsOpsatFolkepensionPayOutType {
  Livsvarigt = "livsvarigt",
  TiAaarigt = "tiAaarigt",
  SumOgTiAarigt = "sumOgTiAarigt",
}

export enum PublicBenefitsTidligPensionYears {
  None = "none",
  OneYear = "oneYear",
  TwoYear = "twoYear",
  ThreeYear = "threeYear",
}

/** If no recommendation settings are specifically set, the default option for the various economic domains is selected. */
export interface RecommendationSettings {
  /**
   * Default: 75% coverage ratio goal seek.
   * @default "Calculation.Api.Model.Request.PoliciesAndCoverages.Recommendation.Pension.PensionCoverageRatioGoalSeek, Calculation.Api.Model, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
   */
  primaryPensionRecommendation?:
    | AverageDisposableIncomeGoalSeek
    | PensionCoverageRatioGoalSeek
    | SimpleSpecifiedPension
    | SpecifiedNetVoluntaryPayment
    | null;
  /**
   * Default: 75% coverage ratio goal seek.
   * @default "Calculation.Api.Model.Request.PoliciesAndCoverages.Recommendation.Pension.PensionCoverageRatioGoalSeek, Calculation.Api.Model, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
   */
  spousePensionRecommendation?:
    | AverageDisposableIncomeGoalSeek
    | PensionCoverageRatioGoalSeek
    | SimpleSpecifiedPension
    | SpecifiedNetVoluntaryPayment
    | null;
  /**
   * Default: 50% restricted loan to value ratio goal seek.
   * @default "Calculation.Api.Model.Request.Loan.Recommendation.RestrictedLoanToValueRatioGoalSeek, Calculation.Api.Model, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
   */
  loanRecommendation?: LoanToValueRatioGoalSeek | RestrictedLoanToValueRatioGoalSeek | SpecifiedAmortizationLoan | null;
  /**
   * Default: 125% cash to salary ratio.
   * @default "Calculation.Api.Model.Request.LiquidAssets.EmergencyRecommendation.CashToSalaryRatio, Calculation.Api.Model, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
   */
  emergencySavingRecommendation?: CashSavingsWithSpecifiedContribution | CashToSalaryRatio | DefinedCashSavings | null;
  /**
   * Default: No recommendation.
   * @default "Calculation.Api.Model.Request.Company.Recommendation.NoCompanyRecommendation, Calculation.Api.Model, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
   */
  companyRecommendation?: NoCompanyRecommendation | SpecifiedDividends | TopTaxOptimizedDividends | null;
}

/** The redemption details associated with early redemption of the loan. */
export interface RedemptionDetails {
  /** @format double */
  redemptionFee?: number;
  /**
   * Only relevant for bond based mortgages. Otherwise, the loan proceeds is deemed equal to the loan principal baring costs.
   * @format double
   */
  bondPrice?: number | null;
}

export interface RefinancingDetails {
  /**
   * Sum of all fees and expenses related to the refinancing.
   * @format double
   */
  totalRefinancingFees?: number;
  /**
   * The final redemption amount. Accounts for bond price.
   * @format double
   */
  redemptionAmount?: number;
  /**
   * The final change in loan principal due to refinancing fees and bond price differences.
   * This is the difference between the new and existing remaining loan principal.
   * @format double
   */
  finalChangeInLoanPrincipal?: number;
}

/**
 * The recommendation uses goal seeking to obtain the specified loan-to-value ratio. If no ratio is specified the default value is used.
 * This approach is restricted and more closely resemble a real mortgage, in the way that the installment cannot vary without limits.
 * Currently this means, that instead the interest only period is modified in the goal seek algorithm seeking to find the required interest only periods to reach the desired target ratio (if possible).
 * In case the mortgage needs to be paid back faster, the option [LoanToValueRatioGoalSeek](#model-LoanToValueRatioGoalSeek) is used.
 */
export type RestrictedLoanToValueRatioGoalSeek = LoanRecommendation & {
  /**
   * @format double
   * @default "50 percent"
   */
  ratio?: number;
};

export type RiskCoverage = Coverage & {
  contribution?: RiskCoverageContribution | null;
  /** Risk coverage pay out configuration. */
  payOut?: RiskCoveragePayOut | null;
  /** Country specific information about the risk coverage type and behavior. If not specified, defaults to a risk coverage information of the selected Calculation Country. */
  riskCoverageInformation?: DkRiskCoverageInformation | UkRiskCoverageInformation | null;
};

export type RiskCoverageContribution = Contribution & object;

export interface RiskCoverageInformation {
  /**
   * * [DkRiskCoverageInformation](#model-DkRiskCoverageInformation)
   * * [UkRiskCoverageInformation](#model-UkRiskCoverageInformation)
   */
  type: string;
}

export interface RiskCoveragePayOut {
  /**
   * The expected pay out of the risk coverage in case of an insurance event. Subject to change.
   * @format double
   */
  amount?: number;
  /**
   * The payout receiver of the insurance in case of event. Only used if applicable for the insurance type. If in doubt - don't use it.
   * @default null
   */
  payoutReceiver?: PayoutReceiver | null;
}

export type RiskCoverageWithProductInformation = RiskCoverage & {
  priceRule?: PriceRange | PriceStepRule | null;
  /**
   * If true, a coverage of this type will always be created and override the recommendation process.
   * If false, then the coverage will by default not be added to the recommendation unless it is needed to reach the required target.
   */
  isMandatory?: boolean;
};

export interface SavingsBaselineAndRecommendedOutput {
  baseline?: SavingsOutput | null;
  recommendation?: SavingsOutput | null;
}

export interface SavingsOutput {
  /** @format double */
  depotAtPension?: number;
  /**
   * The average monthly savings (if positive) or withdrawals (if negative).
   * @format double
   */
  averageMonthlySavings?: number;
}

/** Scenario type for messages. */
export enum Scenario {
  Baseline = "Baseline",
  Recommendation = "Recommendation",
}

/** A simple specified pension, whose parameters override the baseline input for the recommendation calculation. */
export type SimpleSpecifiedPension = PensionRecommendation & {
  /**
   * Specific voluntary extra pension payment.
   * This overrides any voluntary extra pension payment on the baseline scenario.
   * @format double
   * @min 0
   */
  voluntaryPayment: number;
};

export type SinglePensionRecommendationProduct = PensionRecommendationProduct & {
  /**
   * The Id of the pension to change for the pension recommendation. If not defined, or not found in the existing coverages,
   * a new life annuity pension with a new id will be created for the person.
   * @default null
   */
  idOfPensionToChange?: string | null;
};

/**
 * A specified loan, whose parameters override the baseline input. At least one parameter must be defined.
 *
 * See [AmortizedLoan](#model-AmortizedLoan)
 */
export type SpecifiedAmortizationLoan = LoanRecommendation & {
  /** @format int32 */
  remainingTenure?: number | null;
  /** @format int32 */
  remainingRepaymentFreeYears?: number | null;
};

export type SpecifiedDividends = CompanyRecommendation & {
  /**
   * If not specified, the baseline dividends will be used.
   * @format double
   */
  dividends?: number | null;
};

export type SpecifiedNetVoluntaryPayment = PensionRecommendation & {
  /**
   * Monthly net voluntary pension payment.
   * This is converted into the approximated voluntary gross pension contribution before overriding any voluntary pension payment on the baseline scenario.
   * @format double
   * @min 0
   */
  monthlyNetVoluntaryPayment: number;
};

/** A specified policy is always assumed to be a new policy with no prior contributions. */
export type SpecifiedPolicyRecommendationProduct = PensionRecommendationProduct & {
  /**
   * The recommendation pension coverage, which contribution might be altered.
   * If not defined, a new life annuity pension with a new id will be created for the person.
   */
  recommendationCoverage?: PensionCoverage | null;
  /** The list of risk coverages to use for the disability recommendation. */
  disabilityRecommendationCoverages?: RiskCoverageWithProductInformation[] | null;
  /** The list of risk coverages to use for the death recommendation. */
  deathRecommendationCoverages?: RiskCoverageWithProductInformation[] | null;
  /** Fixed coverages. */
  fixedCoverages?: (RiskCoverage | RiskCoverageWithProductInformation | PensionCoverage)[] | null;
};

export interface StandardValuesChild {
  name?: string | null;
  birthDate?: PersonBirthDate | null;
  childAgeGroup?: StandardValuesChildChildAgeGroup | null;
}

export enum StandardValuesChildChildAgeGroup {
  None = "none",
  Daycare = "daycare",
  Kindergarten = "kindergarten",
  AfterSchoolCare = "afterSchoolCare",
  Adult = "adult",
}

export interface StandardValuesInputContract {
  standardValuesProfile?: StandardValuesProfile | null;
  houses?: (HousingBase | HousingWithValue | Housing | HousingWithBbr)[] | null;
  vehicles?: Vehicle[] | null;
}

export interface StandardValuesOutputContract {
  profile?: Profile | null;
  expenseGroups?: ExpenseGroup[] | null;
}

export interface StandardValuesPerson {
  name?: string | null;
  birthDate?: PersonBirthDate | null;
}

export interface StandardValuesProfile {
  primary?: StandardValuesPerson | null;
  spouse?: StandardValuesPerson | null;
  children?: StandardValuesChild[] | null;
  /** @format int32 */
  municipalityId?: number | null;
}

export interface Statement {
  accounts?: Account[] | null;
}

export interface StatementResponse {
  baseline?: Statement | null;
  recommendation?: Statement | null;
  /** There is only calculated and created a subset of delta accounts compared to the Baseline and Recommendation statements. */
  delta?: Statement | null;
}

export interface Time {
  /** @format int32 */
  offset?: number | null;
  timeType?: TimeType | null;
  /** @format int32 */
  year?: number | null;
  convention?: TimeConvention | null;
}

export enum TimeConvention {
  Primo = "primo",
  Ultimo = "ultimo",
}

export enum TimeType {
  Origin = "origin",
  Defined = "defined",
  Calculation = "calculation",
  AtPension = "atPension",
  AtPensionSpouse = "atPensionSpouse",
  AtDeath = "atDeath",
  AtDeathSpouse = "atDeathSpouse",
  AtFirstPension = "atFirstPension",
  AtLastPension = "atLastPension",
  AtFirstDeath = "atFirstDeath",
  AtLastDeath = "atLastDeath",
}

export interface TimeValueOfPercent {
  time?: Time | null;
  value?: Percent | null;
}

/**
 * This recommendation currently specifies the dividend pay out to be exactly equal to the allowed top tax bracket.
 * This is therefore not always the most optimal, but the fastest by which you can withdraw money from the company without paying stock top tax.
 */
export type TopTaxOptimizedDividends = CompanyRecommendation & object;

export type UkRiskCoverageInformation = RiskCoverageInformation & {
  /**
   * The risk coverage type. Currently just defined as a subset of the possible Uk risk coverage types.
   * @default "LumpSumAtDeath"
   */
  coverageType?: UkRiskCoverageType;
};

export enum UkRiskCoverageType {
  LumpSumAtDeath = "LumpSumAtDeath",
  AnnuityAtDeath = "AnnuityAtDeath",
  LumpSumAtDisability = "LumpSumAtDisability",
  AnnuityAtDisability = "AnnuityAtDisability",
  CriticalIllness = "CriticalIllness",
  AccidentInsuranceAtDeath = "AccidentInsuranceAtDeath",
}

export interface Vehicle {
  /** @format int32 */
  id?: number | null;
  description?: string | null;
  type?: VehicleType | null;
  mileageGroup?: VehicleMileageGroup | null;
}

export enum VehicleMileageGroup {
  LessThan10000 = "lessThan10000",
  LessThan20000 = "lessThan20000",
  LessThan30000 = "lessThan30000",
}

export enum VehicleType {
  SmallCar = "smallCar",
  MediumCar = "mediumCar",
  LargeCar = "largeCar",
  Motorcycle = "motorcycle",
  Other = "other",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data.data;
    });
  };
}

/**
 * @title Dreamplan Calculation API
 * @version v1
 *
 * This API performs the required backend calculations of the Dreamplan frontend. The retirement calculations depends on the Keylane FCS API endpoint being available.
 */
export class Client<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
 * @description The five financial domains of interest are: - Pension savings. - House and mortgage. - Emergency savings. - If self-employed: Savings within the company. - Liquid asset savings. The recommendation calculations are all independent except for the liquid asset projection which is dependent on the other recommendation outcomes.
 *
 * @tags Calculation
 * @name CalculateTargetPrices
 * @summary Calculates the financial situation of the household from now until retirement.
This includes the baseline and the recommendation scenarios.
 * @request POST:/api/CalculateTargetPrices
 * @response `200` `CalculateResponse` Returns a CalculateResponse
 * @response `400` `void` Validation error
 * @response `500` `void` Internal error. This may also be due to an error in the Keylane API
 */
    calculateTargetPrices: (data: CalculateRequest, params: RequestParams = {}) =>
      this.request<CalculateResponse, void>({
        path: `/api/CalculateTargetPrices`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * @description The different concerns of this calculation is as follows: - Disability event. How is the financial situation of the household in case the primary person gets disabled. - Death event. How is the financial situation of the household in case the primary person dies. - Other possible insurance coverages - this can include critical illness and different types of accident insurance. The output consists of the different key figures for these events as well as the Statements for the disability, death and no event scenarios. The recommendation calculations are all independent.
 *
 * @tags Calculation
 * @name CalculateInsuranceEvents
 * @summary Calculates the general insurance situation and different events.
This includes the baseline and the recommendation scenarios.
 * @request POST:/api/CalculateInsuranceEvents
 * @response `200` `CalculateInsuranceResponse` Returns a CalculateInsuranceResponse
 * @response `400` `void` Validation error
 * @response `500` `void` Internal error. This may also be due to an error in the Keylane API
 */
    calculateInsuranceEvents: (data: CalculateInsuranceRequest, params: RequestParams = {}) =>
      this.request<CalculateInsuranceResponse, void>({
        path: `/api/CalculateInsuranceEvents`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculateStandardValues
     * @summary Calculates the standard values of a given household.
     * @request POST:/api/CalculateStandardValues
     * @response `200` `StandardValuesOutputContract` Returns the generated standard values.
     * @response `500` `void` Internal error. This may also be due to an error in the Keylane API
     */
    calculateStandardValues: (data: StandardValuesInputContract, params: RequestParams = {}) =>
      this.request<StandardValuesOutputContract, void>({
        path: `/api/CalculateStandardValues`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Inclusion of the recommendation scenario can be toggled by setting the includeRecommendation property in the OutputConfiguration.
     *
     * @tags Calculation
     * @name CalculatePension
     * @summary Calculates the pension situation for a given household, including both baseline and recommendation scenarios.
     * @request POST:/api/CalculatePension
     * @response `200` `CalculatePensionResponse` Returns a CalculatePensionResponse
     * @response `400` `void` Validation error
     * @response `500` `void` Internal error during pension calculation.
     */
    calculatePension: (data: CalculatePensionRequest, params: RequestParams = {}) =>
      this.request<CalculatePensionResponse, void>({
        path: `/api/CalculatePension`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  healthz = {
    /**
     * @description Returns the health status of the service.
     *
     * @tags Health Check API
     * @name HealthzList
     * @request GET:/healthz
     * @response `200` `HealthStatus` API service is healthy
     * @response `503` `HealthStatus` API service is not healthy
     */
    healthzList: (params: RequestParams = {}) =>
      this.request<HealthStatus, HealthStatus>({
        path: `/healthz`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
